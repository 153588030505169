<template>
  <div class="form">
    <div class="block" v-if="type == 0">
      <section>New cell phone number</section>
      <input
        type="text"
        placeholder="Please enter a new cell phone number"
        v-model="phone"
        @focus="f1"
      />
      <div class="hint" v-if="e1">{{ msg1 }}</div>
      <div class="button">
        <span @click="sub">submit</span>
      </div>
    </div>

    <div class="block" v-if="type == 1">
      <section>Set a new password</section>
      <input
        type="text"
        placeholder="Please enter your password"
        v-model="password"
        @focus="f2"
      />
      <div class="hint" v-if="e2">{{ msg2 }}</div>
      <div class="button">
        <span @click="sub">submit</span>
      </div>
    </div>

    <div class="block" v-if="type == 2">
      <section>New email number</section>
      <input
        type="text"
        placeholder="Please enter a new email"
        v-model="email"
        @focus="f3"
      />
      <div class="hint" v-if="e3">{{ msg3 }}</div>
      <div class="button">
        <span @click="sub">submit</span>
      </div>
    </div>
  </div>
</template>
<script>
import { changemobile, changeemail, changepwd } from "network/user";
import Cookie from "cookie_js";
export default {
  name: "Alter",
  data() {
    return {
      type: 0,
      phone: "",
      password: "",
      email: "",
      e1: false,
      e2: false,
      e3: false,
      msg1: "Please enter your phone number",
      msg2: "Please enter your password",
      msg3: "Please enter your email",
    };
  },
  created() {
    this.type = this.$route.params.type;
  },
  methods: {
    sub() {
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (this.type == 0) {
        if (this.phone == "") {
          this.e1 = true;
          return;
        }
        changemobile(this.phone).then((res) => {
          if (res.data.code == 1) {
            this.$notify({
              message: "success",
              type: "success",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
            this.$router.push("/member/security");
          }
        });
      } else if (this.type == 1) {
        if (this.password == "") {
          this.e2 = true;
          this.msg2 = " Please enter your password";
          return;
        } else if (this.password.length < 6) {
          this.e2 = true;
          this.msg2 = " Passwords need a minimum of 6 digits";
          return;
        }
        changepwd(this.password).then((res) => {
          if (res.data.code == 1) {
            this.$notify({
              message: "success",
              type: "success",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
            this.$router.push("/signIn");
            // this.$store.commit("loginStausF");
            // Cookie.remove(["token", "login"]);
            this.$store.dispatch("setAccount", {});
          }
        });
      } else if (this.type == 2) {
        if (this.email == "") {
          this.e3 = true;
          this.msg3 = "Please enter your email ";
          return;
        } else if (!verify.test(this.email)) {
          this.e3 = true;
          this.msg3 = "Please enter a valid email";
          return;
        }
        changeemail(this.email).then((res) => {
          if (res.data.code == 1) {
            this.$notify({
              message: "success",
              type: "success",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          }
          this.$router.push("/member/security");
        });
      }
    },
    f1() {
      this.e1 = false;
    },
    f2() {
      this.e2 = false;
    },
    f3() {
      this.e3 = false;
    },
  },
};
</script>
<style scoped>
.form {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.block {
  width: 70%;
  margin: 0 auto;
}
.block section {
  color: #666666;
  font-size: 0.35rem;
  margin-top: 3rem;
}
.block input {
  width: 100%;
  border: 1px solid #ededed;
  font-size: 0.35rem;
  padding: 0.15rem 0.25rem;
  border-radius: 0.15rem;
}

.button {
  text-align: center;
  padding: 1.5rem 0 2.5rem 0;
}
.button span {
  cursor: pointer;
  color: #fff;
  padding: 0.15rem 0.5rem;
  background: #4d4d4d;
  font-size: 0.35rem;
  border-radius: 0.1rem;
}
.hint {
  color: red;
  font-size: 0.3rem;
}
@media (max-width: 992px) {
  .block {
    width: 90%;
  }
}
</style>